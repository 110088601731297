import { CustomTtpService } from './../services/custom-ttp.service';
import { TokenService } from './../services/token.service';
import { TitleService } from './../services/title.service';
import { AppConfig } from './../app.config';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { SignalRConnectionInfo } from './../services/signalr-connection-info.model';
import { HubConnection } from '@microsoft/signalr';
import { HttpClient } from '@angular/common/http';
import { Llamado } from './../models/llamado';
import { SignalRService } from './../services/signalr.service';
import { AfterViewInit, Component, OnInit, Renderer2  } from '@angular/core';
import {Howl, Howler} from '../../assets/howler/howler.js';
import { ActivatedRoute } from '@angular/router';
import * as signalR from "@microsoft/signalr";

const {Howl, Howler} = require('../../assets/howler/howler.js');

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css']
})
export class ListadoComponent implements OnInit{

  bShowLlamado = false;
  bEmpty       = true;
  medico: string = '';
  piso: string = '';
  paciente: string = '';
  box: string = '';
  idPantalla: string = '';
  pac:    Llamado[] = new Array();
  pacAux: Llamado[] = new Array();
  maxRow: number = 0;
  constructor(
    private renderer: Renderer2,
    private signalRService: SignalRService,
    private activatedRoute: ActivatedRoute,
    private titleService: TitleService,
    private config: AppConfig,
    private tokenService: TokenService,
    private customTtpService: CustomTtpService
  ) {

  }

  ngOnInit() {
  //  this.renderer.addClass(document.body, 'background-none');
  //  this.renderer.removeClass(document.body, 'llamado-bg');

    console.log(this.activatedRoute.snapshot.paramMap.get('id'));
    this.idPantalla = this.activatedRoute.snapshot.paramMap.get('id');

    this.titleService.dispBut.subscribe((value) => {
      this.bShowLlamado = value;
      console.log('value', value)
    });
    this.maxRow = this.config.get("maxRow");
    console.log('Cantidad de registros máximos ', this.maxRow);
    this.getData(this.idPantalla)
  }

    async getData(pantalla: string){
    await this.signalRService.init(pantalla);
    this.signalRService.signalReceived.subscribe({
      next: message => {
        console.log('inicio mensaje', message);
        let decriptedMessage = JSON.parse(this.customTtpService.getDataFromScreen(message));
        if(decriptedMessage['estado'] === 'L') {
          let paciente:Llamado = new Llamado;
          let nomPac = decriptedMessage['nombreusuario'].split(' ');
         /* Formato Nombre inicial AP
          if (nomPac.length >= 2){  
            paciente.paciente = nomPac[0] + ' ' + nomPac[1].substring(0,1) + '.' 
          } else {
            paciente.paciente = (nomPac[0]).toUpperCase();
          }
          */
          if (nomPac.length == 3){
            paciente.paciente = (nomPac[1] + ' ' + nomPac[2].substring(0,1)+ '.').toUpperCase();
          } else if (nomPac.length == 4) {
            paciente.paciente = (nomPac[2] + ' ' + nomPac[3].substring(0,1)+ '.').toUpperCase();
          } else if (nomPac.length == 2) {
            paciente.paciente =  (decriptedMessage['nombreusuario']).toUpperCase();
          }
           else {
            paciente.paciente = (nomPac[0]).toUpperCase();
          }

          paciente.medico = decriptedMessage['nombremedico'];
          paciente.box =  decriptedMessage['box'];
          paciente.piso = decriptedMessage['piso'];
          paciente.id = decriptedMessage['IdMedic'];
          paciente.idx = new Date().getTime();
          this.fillArray(paciente);
          this.bShowLlamado = true;
          this.titleService.disableButton(true);
        }
        console.log('fin Mensaje');
      }});
    this.listarPacientes(this.idPantalla);
  }

  fillArray(pac:Llamado){
    let pacData = this.pac.find(i => i.id === pac.id);
    if (pacData === undefined){
      if (this.pac.length == this.maxRow) {
        console.log('borrando ultimo registro');
        this.pac.pop();
      }
      this.pacAux.push(pac);
      this.pacAux = this.pacAux.sort(function(a,b){
        return (a.idx > b.idx ? -1 : 1);
      });
      this.pac = this.pacAux;
    }
    this.medico = pac.medico;
    this.piso = pac.piso.toString();
    this.paciente = pac.paciente;
    this.box = pac.box.toString();
    this.bEmpty = this.pac.length === 0;
  }

  async listarPacientes (id: string){
    let response = await this.tokenService.listarPacientes(id);
    console.log(response);
    if (response !== undefined && response.length > 0 ){
      for (let i = 0; i < response.length ; i++){
        let paciente:Llamado = new Llamado;
        if (!this.isToday(new Date(response[i]["fecha"])))
          continue;
        let nomPac = response[i]['nombreusuario'].split(' ');
        /*Formato Nombre inicial AP
        if (nomPac.length >= 2){
          paciente.paciente = nomPac[0] + ' ' + nomPac[1].substring(0,1) + '.' 
        } else {
          paciente.paciente = (nomPac[0]).toUpperCase();
        }*/
        if (nomPac.length == 3){
          paciente.paciente = (nomPac[1] + ' ' + nomPac[2].substring(0,1)+ '.').toUpperCase();
        } else if (nomPac.length == 4) {
          paciente.paciente = (nomPac[2] + ' ' + nomPac[3].substring(0,1)+ '.').toUpperCase();
        } else if (nomPac.length == 2) {
          paciente.paciente =  (response[i]['nombreusuario']).toUpperCase();
        } else {
          paciente.paciente = (nomPac[0]).toUpperCase();
        }
        paciente.medico = response[i]['nombremedico'];
        paciente.box =  response[i]['box'];
        paciente.piso = response[i]['piso'];
        paciente.id = response[i]['idMedic'];
        paciente.idx = new Date(response[i]['fechallamado']).getTime();
        this.fillArray(paciente);
      }
    }
  }

  isToday(someDate:Date){
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }
}
